<template>
    <div>
        <v-card id="ad-insertion_datatable">
            <QDataTableHeader class="q_leads_1">
                <template #left>
                    <p class="q-display-3 pb-0 pt-0 mt-0 mb-0 white--text">{{ title }}
                        <v-btn v-if="moreLink" text fab :to="{name:moreLink}">
                            <v-icon color="q_leads_4">fas fa-arrow-circle-right</v-icon>
                        </v-btn>
                        <v-btn dark icon @click="refreshData">
                            <v-icon>fas fa-redo-alt</v-icon>
                        </v-btn>
                        <v-btn class="ml-10" @click="newMarket">Add New Market</v-btn>
                    </p>
                </template>
                <template #right>
                    <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                </template>
            </QDataTableHeader>
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <QExpandableDataTable 
                v-on:current-items="updateCurrentItems" 
                :visible-headers="visibleHeaders" 
                :mobile-breakpoint="2" 
                :items-per-page.sync="rows" 
                :search="search" 
                v-model="selected" 
                :headers="the_headers" 
                :items="all_data" 
                item-key="ID" 
                class="elevation-0" 
                :footer-props="{itemsPerPageOptions:[10,25,50,100]}" 
                :loading="loading" 
                :options.sync="options" 
                :server-items-length="total_items" 
                color="q_leads_1"
            >
                <template v-slot:item.AgentName="{ item }">
                    <v-badge :content="item.Division" :overlap="false" inline :offset-x="-5" color="primary" overlap>{{item.AgentName}}</v-badge>
                </template>
                <template v-slot:item.ID="{ item }">
                    <span class="nowrap">
                        <v-btn icon color="q_new_b_1 white--text  body-2" @click="editMarket(item)">
                            <v-icon>fas fa-edit</v-icon>
                        </v-btn>
                        <v-btn icon color="red white--text  body-2" @click="deleteMarket(item)">
                            <v-icon>fas fa-trash</v-icon>
                        </v-btn>
                    </span>
                </template>
                <template v-slot:item.AgentEmail="{ item }">
                    <a :href="'mailto:' + item.AgentEmail">{{ item.AgentEmail }}</a>
                </template>
                <template v-slot:item.AgentPhone="{ item }">
                    <a :href="'tel:' + item.AgentPhone">{{ item.AgentPhone }}</a>
                </template>
                <template v-slot:item.StreetAddress="{ item }">
                    <span class="nowrap">
                        {{ item.AgentName }}<br>
                        {{ item.StreetAddress }}<br>
                        {{ item.City }}, {{ item.State }} {{ item.Zip }}<br>
                    </span>
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td colspan=2>
                            <v-btn @click="downloadData">Export</v-btn>
                        </td>
                    </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" v-if="$vuetify.breakpoint.xl">
                        <v-row wrap>
                            <v-col cols="4">
                                <div class="overline">Email:</div>
                                <a :href="'mailto:' + item.AgentEmail">{{ item.AgentEmail }}</a>
                            </v-col>
                            <v-col cols="8">
                                <div class="overline">Phone:</div>
                                <a :href="'tel:' + item.AgentPhone">{{ item.AgentPhone }}</a>
                            </v-col>
                            <v-col cols="6" sm="4">
                                <div class="overline">Address:</div>
                                {{ item.AgentName }}<br>
                                {{ item.StreetAddress }}<br>
                                {{ item.City }}, {{ item.State }} {{ item.Zip }}<br>
                            </v-col>
                            <v-col cols="6" sm="4">
                                <div class="overline">Agent Code:</div>
                                {{ item.AgentCode }}<br>
                                <div class="overline">OptID:</div>
                                {{ item.OptID }}<br>
                            </v-col>
                        </v-row>
                    </td>
                </template>
            </QExpandableDataTable>
        </v-card>
        <v-dialog v-model="show_edit_market" width="400">
            <v-card class="pa-6 text-center" min-height="">
                <v-progress-linear color="primary" indeterminate v-if="saving"></v-progress-linear>
                <h3 v-if="currentMarket.ID">Edit Market</h3>
                <h3 v-else>New Market</h3>
                <v-text-field label="Market Name" v-model="currentMarket.MarketName"></v-text-field>
                <!-- <v-text-field label="Seats Available" v-model="currentMarket.SeatsAvailable"></v-text-field> -->
                <v-btn @click="saveMarket">Save</v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin"
import Agent from '@/store/Models/Agent'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDataTableHeader from './base/QDataTableHeader.vue'
import { debounce } from 'vue-debounce'
import QExpandableDataTable
from '../datatables/QExpandableDataTable.vue'

export default {
    name: "QAgentsDirectoryTable",
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            expanded: [],
            entity: 'ad-insertion',
            //use this to "preset" a filter
            filters: {},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            options: { itemsPerPage: 10, sortBy: [], sortDesc: [false] },
            the_data: [],
            meta: {},
            loading_data: false,
            show_edit_market: false,
            currentMarket: {},
            saving: false
        }
    },
    computed: {
        filter_keys: function() {
            return ['Status']
        },
        all_data: function() {
            return this.the_data
        },
        expandTableHeaders() {
            return []
        },
        the_headers: function() {
            const headers = [{
                text: 'Market',
                value: 'MarketName',
                align: 'start',
                sortable: true,
                filterable: true,
                class: 'nowrap',
            }, {
            //     text: 'Seats',
            //     value: 'SeatsAvailable',
            //     align: 'center',
            //     class: 'nowrap',
            // }, {
            //     text: 'Available',
            //     value: 'CurrentSeatsAvailable',
            //     align: 'center',
            //     class: 'nowrap',
            // }, {
                text: '',
                value: 'ID',
                align: 'center',
                class: 'nowrap',
            }];

            if (this.$vuetify.breakpoint.lgAndDown) {
                return [
                    ...this.expandTableHeaders,
                    ...headers,
                ]
            }

            return headers
        },

        visibleHeaders() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [{
                        text: 'Name',
                        value: 'MarketName',
                    },
                    // {
                    //     text: 'Seats',
                    //     value: 'SeatsAvailable',
                    // },
                ]
            }
            if (this.$vuetify.breakpoint.mdAndDown) {
                return this.the_headers.slice(this.expandTableHeaders.length, this.expandTableHeaders.length + 4)
            }

            if (this.$vuetify.breakpoint.lgAndDown) {
                return this.the_headers.slice(this.expandTableHeaders.length, this.expandTableHeaders.length + 6)
            }

            return []
        },
        available_filters: function() {
            return ['Status'];
        },
        loading: function() {
            return this.loading_data
        },
        total_items: function() {
            if(!this.the_data)
                return 0
            return this.meta.total
            // return this.the_data.length;
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            var g = this
            // opt.itemsPerPage = 'all'
            // if (this.the_data.length > 0)
            //     return

            this.loading_data = true
            QuilityAPI.getAdInsertionMarkets(this.filters, { attr: '', ...opt }).then(function(json) {
                g.loading_data = false
                g.the_data = json.data
                g.meta = json.meta
            }).catch(function(err) {
                g.loading_data = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                //g.showError(err.status)
            });
            this.firstPull = false;
        }, 200),

        downloadData: debounce(function() {
            var opt = {
                ...this.options,
                search: this.search,
            }
            var endpoint = '/api/private/ad_market';
            var params = {
                ...opt,
                ...this.filters,
                'itemsPerPage': "csv",
                'attr': ''
            }
            this.dispatchJob({
                'method': "GET",
                'path': endpoint,
                'params': params,
                'JobName': "Ad Market Export"
            })
        }, 200),

        truncateEmail: function(email) {
            let threshold = 30

            if (email.length <= threshold) {
                return email
            }

            // subtract 3 from threshold to account for the ellipsis
            return `${email.slice(0,(threshold - 3))}...`
        },

        newMarket: function() {
            this.currentMarket = {
                "MarketName": null,
                "ID": null,
                // "AvailableSeats": null
            }
            this.show_edit_market = true
        },

        editMarket: function(i) {
            this.currentMarket = i
            this.show_edit_market = true
        },

        deleteMarket: function(i) {
            var g = this
            g.loading_data = true;
            QuilityAPI.deleteAdInsertionMarket(i).then(function(json) {
                g.loading_data = false
                if (json.msg) {
                    g.showError(json.msg)
                    return
                }
                g.refreshData()
            }).catch(function(err) {
                g.loading_data = false
                g.showError(err)
            });
        },

        saveMarket: function() {
            var g = this
            g.saving = true;
            QuilityAPI.saveAdInsertionMarket(g.currentMarket).then(function(json) {
                g.saving = false
                if (json.msg) {
                    g.showError(json.msg)
                    return
                }
                g.show_edit_market = false
                g.refreshData()
            }).catch(function(err) {
                g.saving = false
                g.showError(err)
            });
        }
    },
    components: {
        QDataTableHeader,
        QExpandableDataTable,
    }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
